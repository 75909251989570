import React from 'react'

import ReviewCard from './ReviewCard'
import Button from './Button'
import * as style from '../styles/reviewPage.module.css'

// doing this for flexability
const reviewData = [
  {
    id: 1,
    review:
      '"Frau Bogdanova ist eine sehr gute Zahnärztin fachkompetent und stets freundlich. Sie nimmt sich Zeit um die Schritte der Behandlung ausführlich zu erklären. Sie spricht mehrere Sprachen und von daher ist es möglich auf verschiedene Sprachen zu kommunizieren. Immer wieder gerne."',
    title: 'Empatische und sehr kompetente Ärztin',
    name: 'Lorem ipsum',
    jameda: '1.0',
  },
  {
    id: 2,
    review:
      '"Zahnbehandlungen sind immer etwas unangenehm, doch Frau Dr. Bogdanova nimmt einem durch ihre kompetente Art alle Sorgen. Sie erklärt immer genau was sie wie und warum macht, damit man das Vorgehen selbst gut verstehen kann. Hier wird einem nichts angedreht, was nicht nötig ist und gegebenenfalls alternative Behandlungsmöglichkeiten abgeklärt und besprochen. Ich kann Frau Bogdanova wärmstens als Zahnärztin empfehlen!"',
    title: 'Sehr nette und kompetente Ärztin!',
    name: 'Lorem ipsum',
    jameda: '1.0',
  },

  {
    id: 3,
    review:
      '"Ich war schon Patient in der alten Praxis wo Frau Bogdanova gearbeitet hatte. Jetzt muss ich zwar 30 Min. länger fahren, aber das ist es mir Wert. Wichtig ist, daß sie sich Zeit nimmt für die Behandlung, und alles ordentlich gemacht wird. Nicht wie am Fließband. Die Preise sind fair, und es werden keine unnötigen Behandlungen durchgeführt die dem Patienten nur das Geld aus der Tasche ziehen. Von daher komme ich immer wieder gerne. Zu empfehlen ist die professionelle Zahnreinigung."',
    title: 'Kompetente und freundliche Ärztin',
    name: 'Lorem ipsum',
    jameda: '1.2',
  },
]

function ReviewPage() {
  return (
    <div id="meinung" className={style.reviewPage}>
      <h2>Meinungen Unserer Patienten</h2>
      <div className={style.jameda}>
        <h3>Das Wichtigste für uns ist die Meinung unserer Patienten</h3>
        <h3>Besuchen Sie uns auf:</h3>
        <a
          href="https://www.jameda.de/berlin/zahnaerzte/veneta-bogdanova/uebersicht/81577423_1/"
          target="_blank" rel="noopener"
        >
          <Button name="Jameda" />
        </a>
      </div>
      <div className={style.container}>
        {reviewData.map(data => (
          <ReviewCard
            key={data.id}
            review={data.review}
            title={data.title}
            jameda={data.jameda}
          />
        ))}
      </div>
    </div>
  )
}

export default ReviewPage
