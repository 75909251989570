import React from "react"

export default function Logo({height}) {
  return (
    <svg height={height} viewBox="0 0 317 114" fill="none">
      <path
        class="arm"
        d="M71.52 44.25C74.23 42.15 65.96 47.04 58.31 44.49C49.62 41.6 36.84 36.31 35.31 46.54C33.87 56.43 41.12 66.85 41.12 66.85C41.12 66.85 35.3 44.28 45 45.63C55.52 47.08 62.35 51.33 71.52 44.25V44.25Z"
        fill="#727373"
      />
      <path
        class="head"
        d="M42.54 36.08C45.2959 36.08 47.53 33.8459 47.53 31.09C47.53 28.3341 45.2959 26.1 42.54 26.1C39.7841 26.1 37.55 28.3341 37.55 31.09C37.55 33.8459 39.7841 36.08 42.54 36.08Z"
        fill="#727373"
      />
      <path
        class="tooth"
        d="M60.94 41.78C65.39 32.6 110.16 23.87 74.53 87.67C72.68 90.99 71.86 90.38 69.46 80.84C66.95 70.84 62.41 69.53 58.83 70.08C53.65 70.87 52.09 79.2 54.91 86.19C56.06 89.03 57.18 90.19 57.08 90.57C56.9 91.22 51.33 84.97 50.28 80.65C48.71 74.23 49.07 65.41 60.1 65.04C73.32 64.61 72.58 86.26 73.23 85.14C81.88 70.03 89.49 37.57 69.45 39.68C61.86 40.48 59.99 43.76 60.94 41.78V41.78Z"
        fill="#55A245"
      />
      <path
        class="circle"
        d="M38.65 100.63C30.3626 98.0943 22.8837 93.4327 16.9575 87.109C11.0312 80.7852 6.86419 73.0199 4.87106 64.5855C2.87794 56.1512 3.12819 47.342 5.59691 39.0344C8.06564 30.7269 12.6668 23.2106 18.9425 17.2335C25.2182 11.2564 32.9497 7.02686 41.3676 4.96574C49.7856 2.90462 58.5965 3.08377 66.9238 5.48537C75.251 7.88698 82.8041 12.4273 88.8317 18.6546C94.8592 24.8818 99.151 32.5789 101.28 40.98C99.4448 31.8713 95.1663 23.4334 88.9029 16.57C82.6395 9.70665 74.627 4.67635 65.7237 2.01786C56.8204 -0.640626 47.3616 -0.827148 38.3605 1.47829C29.3593 3.78374 21.1548 8.49429 14.6257 15.1054C8.09654 21.7166 3.48879 29.9792 1.2959 39.0085C-0.896995 48.0378 -0.592419 57.4935 2.17701 66.3629C4.94643 75.2323 10.0764 83.1814 17.0174 89.3586C23.9585 95.5359 32.4491 99.7086 41.58 101.43C40.63 101.2 39.64 100.94 38.65 100.63Z"
        fill="#727373"
      />
      <path
        d="M101.66 57.75V70.21H116.05V57.75H119.91V87.51H116.05V73.51H101.66V87.51H97.8V57.75H101.66Z"
        fill="#727373"
      />
      <path
        d="M141.99 73.56H130.45V84.31H143.36V87.51H126.59V57.75H142.68V60.95H130.45V70.38H141.99V73.56Z"
        fill="#727373"
      />
      <path
        d="M148.28 57.75H152.14V84.27H164.87V87.51H148.28V57.75Z"
        fill="#727373"
      />
      <path
        d="M169.16 57.75H173.03V84.27H185.75V87.51H169.16V57.75Z"
        fill="#727373"
      />
      <path
        d="M205.41 73.56H193.87V84.31H206.77V87.51H190.01V57.75H206.09V60.95H193.87V70.38H205.41V73.56Z"
        fill="#727373"
      />
      <path
        d="M211.28 58.16C214.282 57.7102 217.315 57.4962 220.35 57.52C226 57.52 229.69 58.52 232.54 60.71C235.63 63.02 237.54 66.65 237.54 71.91C237.54 77.61 235.48 81.56 232.62 83.97C229.49 86.58 224.69 87.82 218.89 87.82C216.336 87.8468 213.784 87.6998 211.25 87.38L211.28 58.16ZM218 82.44C218.779 82.5412 219.565 82.5814 220.35 82.56C226.44 82.61 230.45 79.25 230.45 72.13C230.45 65.95 226.84 62.69 221.05 62.69C220.027 62.6698 219.005 62.757 218 62.95V82.44Z"
        fill="#55A245"
      />
      <path
        d="M259.74 74.92H248.74V82H260.99V87.51H241.99V57.75H260.33V63.26H248.78V69.46H259.78L259.74 74.92Z"
        fill="#55A245"
      />
      <path
        d="M265.64 87.51V57.75H273.48L279.66 68.66C281.504 71.9488 283.127 75.3563 284.52 78.86H284.63C284.233 74.7256 284.053 70.5732 284.09 66.42V57.75H290.28V87.51H283.23L276.85 76.05C274.936 72.6405 273.21 69.1286 271.68 65.53H271.53C271.74 69.47 271.82 73.68 271.82 78.53V87.48L265.64 87.51Z"
        fill="#55A245"
      />
      <path
        d="M301.85 63.4H293.85V57.75H316.76V63.4H308.58V87.51H301.85V63.4Z"
        fill="#55A245"
      />
      <path
        d="M98.86 112.2L105.59 102.35V102.3H99.43V100.98H107.63V101.98L100.94 111.79V111.86H107.72V113.17H98.86V112.2Z"
        fill="#727373"
      />
      <path
        d="M117.55 109.31L116.28 113.12H114.65L118.78 100.98H120.66L124.81 113.12H123.13L121.83 109.31H117.55ZM121.55 108.08L120.34 104.59C120.08 103.79 119.9 103.07 119.72 102.37C119.54 103.08 119.35 103.83 119.11 104.57L117.92 108.08H121.55Z"
        fill="#727373"
      />
      <path
        d="M134.11 100.98V106.07H139.98V100.98H141.55V113.12H139.98V107.43H134.11V113.12H132.54V100.98H134.11Z"
        fill="#727373"
      />
      <path
        d="M150.21 113.12V100.98H151.94L155.82 107.12C156.63 108.387 157.355 109.707 157.99 111.07V111.07C157.85 109.45 157.82 107.97 157.82 106.07V100.98H159.29V113.12H157.7L153.84 106.97C153.011 105.662 152.26 104.306 151.59 102.91H151.53C151.63 104.45 151.65 105.91 151.65 107.91V113.1L150.21 113.12Z"
        fill="#727373"
      />
      <path
        d="M169.97 109.31L168.71 113.12H167.09L171.21 100.98H173.1L177.28 113.12H175.61L174.31 109.31H169.97ZM173.97 108.08L172.77 104.59C172.51 103.79 172.32 103.07 172.14 102.37C171.97 103.08 171.78 103.83 171.54 104.57L170.34 108.08H173.97Z"
        fill="#727373"
      />
      <path
        d="M184.95 101.15C185.94 100.973 186.944 100.886 187.95 100.89C189.204 100.769 190.458 101.126 191.46 101.89C191.784 102.18 192.041 102.536 192.214 102.935C192.387 103.334 192.471 103.765 192.46 104.2C192.463 104.893 192.242 105.569 191.829 106.126C191.416 106.683 190.834 107.091 190.17 107.29V107.29C190.652 107.514 191.069 107.857 191.381 108.288C191.692 108.719 191.888 109.222 191.95 109.75C192.163 110.885 192.474 111.999 192.88 113.08H191.28C190.916 112.143 190.648 111.171 190.48 110.18C190.12 108.51 189.48 107.87 188.04 107.82H186.56V113.08H184.99L184.95 101.15ZM186.52 106.68H188.12C189.8 106.68 190.86 105.76 190.86 104.37C190.86 102.8 189.72 102.11 188.07 102.11C187.55 102.096 187.029 102.143 186.52 102.25V106.68Z"
        fill="#727373"
      />
      <path
        d="M199.75 112.2L206.48 102.35V102.3H200.28V100.98H208.48V101.98L201.79 111.79V111.86H208.57V113.17H199.71L199.75 112.2Z"
        fill="#727373"
      />
      <path
        d="M218.79 102.31H215.09V100.98H224.09V102.31H220.37V113.12H218.8L218.79 102.31Z"
        fill="#727373"
      />
      <path
        d="M231.36 101.13C232.351 100.964 233.355 100.884 234.36 100.89C235.577 100.797 236.787 101.153 237.76 101.89C238.111 102.211 238.388 102.605 238.573 103.043C238.758 103.481 238.845 103.955 238.83 104.43C238.852 104.904 238.781 105.378 238.62 105.824C238.458 106.27 238.21 106.68 237.89 107.03C237.392 107.489 236.807 107.843 236.17 108.071C235.533 108.3 234.856 108.398 234.18 108.36C233.753 108.376 233.326 108.335 232.91 108.24V113.11H231.35L231.36 101.13ZM232.92 106.98C233.346 107.081 233.783 107.125 234.22 107.11C236.12 107.11 237.27 106.18 237.27 104.52C237.27 102.86 236.12 102.13 234.4 102.13C233.904 102.122 233.408 102.162 232.92 102.25V106.98Z"
        fill="#727373"
      />
      <path
        d="M246.87 101.15C247.86 100.973 248.864 100.886 249.87 100.89C251.124 100.769 252.378 101.126 253.38 101.89C253.704 102.18 253.961 102.536 254.134 102.935C254.307 103.334 254.391 103.765 254.38 104.2C254.383 104.893 254.162 105.569 253.749 106.126C253.336 106.683 252.754 107.091 252.09 107.29V107.29C252.572 107.514 252.989 107.857 253.301 108.288C253.612 108.719 253.808 109.222 253.87 109.75C254.083 110.885 254.394 111.999 254.8 113.08H253.18C252.816 112.143 252.548 111.171 252.38 110.18C252.02 108.51 251.38 107.87 249.94 107.82H248.46V113.08H246.89L246.87 101.15ZM248.44 106.68H250.04C251.72 106.68 252.78 105.76 252.78 104.37C252.78 102.8 251.64 102.11 249.99 102.11C249.47 102.096 248.949 102.143 248.44 102.25V106.68Z"
        fill="#727373"
      />
      <path
        d="M264.56 109.31L263.28 113.12H261.68L265.8 100.98H267.69L271.83 113.12H270.16L268.86 109.31H264.56ZM268.56 108.08L267.36 104.59C267.1 103.79 266.91 103.07 266.73 102.37C266.56 103.08 266.37 103.83 266.13 104.57L264.93 108.08H268.56Z"
        fill="#727373"
      />
      <path
        d="M285.94 113.12L284.39 110.44C283.75 109.44 283.39 108.74 282.99 108.05C282.65 108.75 282.3 109.4 281.68 110.45L280.22 113.12H278.42L282.13 106.97L278.56 100.97H280.37L281.99 103.83C282.43 104.61 282.77 105.22 283.11 105.83C283.45 105.11 283.77 104.55 284.21 103.83L285.86 100.97H287.68L283.97 106.86L287.77 113.08L285.94 113.12Z"
        fill="#727373"
      />
      <path d="M297.08 100.98V113.12H295.51V100.98H297.08Z" fill="#727373" />
      <path
        d="M305.55 111.21C306.394 111.723 307.362 111.996 308.35 112C309.95 112 310.89 111.16 310.89 109.93C310.89 108.7 310.24 108.14 308.6 107.53C306.6 106.83 305.39 105.79 305.39 104.09C305.39 102.2 306.96 100.8 309.32 100.8C310.248 100.767 311.169 100.966 312 101.38L311.56 102.67C310.861 102.279 310.071 102.079 309.27 102.09C307.6 102.09 306.97 103.09 306.97 103.91C306.97 105.03 307.71 105.59 309.39 106.24C311.45 107.04 312.48 108.03 312.48 109.81C312.48 111.59 311.11 113.31 308.24 113.31C307.155 113.325 306.085 113.053 305.14 112.52L305.55 111.21Z"
        fill="#727373"
      />
    </svg>
  )
}
