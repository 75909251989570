import React from 'react'
import * as styles from '../styles/footer.module.css'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

export default function Footer() {
  return (
    <footer className={styles.wrap} id="termin">
      <div className={styles.left}>
        <p>
          <span>ZÄ VENETA NENKOVA-BOGDANOVA</span>
        </p>
        <p>Louis-Lewin-Straße 8, 12627 Berlin </p>
      </div>

      <div className={styles.middle}>
        <p>
          <span>ÖFFNUNGSZEITEN</span>
        </p>
        <p>
          <span>Mo</span> 12:30-18:30 <span>Di</span> 8:30-14:00 <span>Mi</span>{' '}
          12:30-18:30
        </p>
        <p>
          <span>Do</span> 8:30-12:30 und 13:00-15:30{' '}
        </p>
        <p>
          <span>Fr</span> nach Vereinbarungen
        </p>
      </div>

      <div className={styles.right}>
        <p>
          <span>KONTAKTDATEN</span>
        </p>
        <p>
          <span>Tel.:</span> (030) 991 70 90
        </p>
        <p>
          <span>`E-Mail:</span> zahnarztpraxis@helledent.de
        </p>
      </div>

      <div className={styles.bottom}>
        <p className={styles.name}>ZÄ Veneta Bogdanova</p>
        <AnchorLink to="/Impressum" className={styles.impressum}>
          Impressum
        </AnchorLink>
        <AnchorLink to="/Daten" className={styles.daten}>
          Datenschutzerklärung
        </AnchorLink>
      </div>
    </footer>
  )
}
