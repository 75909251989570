import React from 'react'
import * as styles from '../styles/team.module.css'

export default function Team() {
  return (
    <div id="unser" className={styles.uTeam}>
      <h2>Unser Team</h2>
      <p className={styles.text}>
        Unser Team der Zahnarztpraxis Helledent behandelt Sie auf bestmögliche
        Art und Weise.
      </p>
      <p className={styles.text2}>
        Angefangen beim herzlichen Empfang an unserer Rezeption, über die
        einfühlsame Behandlung in entspannter Atmosphäre bis hin zum Verlassen
        unsere Praxis bekommen Sie eine rundum zufriedenstellende Betreuung.
      </p>
      <p className={styles.text3}>
        Die Professionalität unserer Praxis spiegelt sich in unserem
        eingespielten Team wieder. Hier setzt jeder seine Stärken ein um Ihren
        ein optimales Behandlungsergebnis zu liefern.
      </p>
      <p className={styles.text4}>Das ist das Team von Helledent</p>
      <div className={styles.person1}>
        <img src="veneta-team.jpg" alt="Zahnarzt Bilder, Veneta Bogdanova " />
        <h3>Frau Veneta Nenkova-Bogdanova</h3>
        <p>Zahnärztin</p>
      </div>
      <div className={styles.lineOne}></div>
      <div className={styles.person2}>
        <img
          src="team1.jpg"
          alt="Verwaltungsassistäntin Bilder, Frau Sylka Jantke "
        />
        <h3>Frau Sylka Jantke</h3>
        <p>Verwaltungsassistentin</p>
      </div>
      <div className={styles.lineTwo}></div>
      <div className={styles.person3}>
        <img
          src="team2.png"
          alt="Auszubildende Bilder, Frau Ailin Wollert"
        />
        <h3>Frau Nargizkhanim Hasanova</h3>
        <p> Auszubildende </p>
      </div>
    </div>
  )
}
