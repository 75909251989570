import React from 'react'

import * as style from '../styles/reviewCard.module.css'

function ReviewCard({ review, title, jameda }) {
  return (
    <div className={style.speachWrapper}>
      <div className={style.jameda}>{jameda}</div>
      <div className={style.speachBubble}>{review}</div>
      <div className={style.title}>
        <h3>{title}</h3>
      </div>
    </div>
  )
}

export default ReviewCard
