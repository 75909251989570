// extracted by mini-css-extract-plugin
export var uTeam = "team-module--uTeam--1xjqh";
export var person1 = "team-module--person1--31YcO";
export var person2 = "team-module--person2--2BHps";
export var person3 = "team-module--person3--21P4N";
export var lineOne = "team-module--lineOne--114Rj";
export var lineTwo = "team-module--lineTwo--3kyfa";
export var text = "team-module--text--1s4BE";
export var text2 = "team-module--text2--3Dozu";
export var text3 = "team-module--text3--3t88l";
export var text4 = "team-module--text4--3NGDu";