import React, { useState } from 'react'
import Logo from '../assets/icons/Logo'
import * as styles from '../styles/navigation.module.css'
import Hamburger from '../assets/icons/Hamburger'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

export default function Navbar() {
  const [toggle, setToggle] = useState(false)

  console.log(toggle)
  return (
    <nav className={styles.nav}>
      <AnchorLink className="styles.logoLink" to="/#home">
        <Logo height={50} />
      </AnchorLink>

      <div
        onClick={() => setToggle(toggle => !toggle)}
        className={`${styles.active} ${toggle ? '' : styles.close}`}
      >
        <AnchorLink to="/#home">Home</AnchorLink>
        <AnchorLink to="/#leistungen">Leistungen</AnchorLink>
        <div className={styles.innerNav}>
          <AnchorLink to="/#uberUns">Über uns</AnchorLink>
          <ul className={styles.menu}>
            <li>
              <AnchorLink to="/#unser">Unser Team</AnchorLink>
            </li>
            <li>
              <AnchorLink to="/#meinung">Meinungen</AnchorLink>
            </li>
          </ul>
        </div>
        <AnchorLink to="/#gallerie">Gallerie</AnchorLink>
        <AnchorLink to="/#contact">Kontakt</AnchorLink>
        <AnchorLink to="/#termin">Termin vereinbaren</AnchorLink>
      </div>
      <div
        className={styles.hamburgerMenuContainer}
        onClick={() => setToggle(toggle => !toggle)}
      >
        <Hamburger active={toggle} height={40} color="#3F7B3B" />
      </div>
    </nav>
  )
}
